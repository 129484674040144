<template>
  <v-sheet>
    <v-card>
      <v-card-title>
        <v-autocomplete
          v-model="account"
          :items="accounts"
          :loading="isLoading"
          :search-input.sync="searchaccount"
          item-text="name"
          item-value="accountid"
          :label="`搜索${role}`"
          hide-details
          flat
          return-object
          :filter="itemFilter"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title>账号: {{ data.item.username }}</v-list-item-title>
                <v-list-item-subtitle> 姓名:{{ data.item.name }} </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="inrole([1, 3])" @click="add">添加{{ role }}</v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        disable-pagination
        :loading="loading"
        hide-default-footer
        item-key="accountid"
        :fixed-header="true"
        dense
      >
        <template v-slot:item.op="props">
          <v-tooltip bottom v-if="inrole([1, 3])">
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                v-if="props.item.accountid != 1"
                @click.native.stop="deleteData(props.item.accountid)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>移除</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有进度</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </v-sheet>
</template>

<script>
import { useUserStore } from "@/stores/userStore.js";
import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, switchMap } from "rxjs/operators";

export default {
  name: "ProjectAccountsTab",
  props: ["projectid", "role"],
  data() {
    return {
      loading: true,
      isLoading: false,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      account: {},
      accounts: [],
      searchaccount: null,
      obs: {
        search$: null,
        searchSub$: null,
      },
      data: [],
      headers: [
        {
          text: "账号",
          value: "username",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "姓名",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "权限等级",
          value: "role",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "联系方式",
          value: "contact",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    searchaccount(val) {
      this.obs.search$.next(val);
    },
  },
  mounted() {
    this.fetchData();
    this.obs.search$ = new BehaviorSubject();
    this.obs.searchSub$ = this.obs.search$
      .asObservable()
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((x) => x && x.length >= 1),
        switchMap((x) => {
          this.isLoading = true;
          return this.$hc
            .req()
            .get(`accounts?r=${this.role}&pagesize=10000&pageindex=1&q=${x || ""}`)
            .go({
              lock: false,
              toastError: true,
              toastSuccess: "",
            });
        })
      )
      .subscribe(
        (x) => {
          this.isLoading = false;
          this.accounts = x.Data.Payload;
        },
        (x) => {
          this.isLoading = true;
        }
      );
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
    itemFilter() {
      return true;
    },
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetchData();
      }
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(`projects/${this.projectid}/accounts?role=${this.role}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteData(accountid) {
      this.$confirm("确定要移除这个账户吗?").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`projects/${this.projectid}/accounts/${accountid}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
    add() {
      if (this.account && this.account.accountid) {
        this.$hc
          .req()
          .post(`projects/accounts`, {
            accountid: this.account.accountid,
            projectid: this.projectid,
          })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchData();
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
