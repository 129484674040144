<template>
  <div class="minhight100">
    <v-data-table
      @click:row="rowClick"
      :headers="headers"
      :items="data"
      :server-items-length="totalrecords"
      :loading="loading"
      hide-default-footer
      item-key="id"
      :fixed-header="true"
      dense
      single-select
      v-model="selected_records"
      :height="myheight"
      class="table-selectable"
    >
      <template v-slot:item.BorrowStatus="props">
        <v-tooltip
          bottom
          v-if="props.item.BorrowStatus>0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :class="{'red--text':props.item.BorrowStatus==1,'orange--text':props.item.BorrowStatus==2}"
              dark
              v-bind="attrs"
              v-on="on"
              small
            >
              circle
            </v-icon>
          </template>
          <span>{{props.item.BorrowStatus==1?"整卷借出":"档案借出"}}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.jieyue="props">
        <v-btn
          @click.stop="openBorrowDialog(1,props.item.archiveid,props.item.id,props.item.name, props.item.record_no)"
          x-small
          class="primary"
        >借阅</v-btn>
      </template>
    </v-data-table>
    <div
      class="text-xs-center pt-2"
      v-if="!fullscreen"
    >
      <v-pagination
        v-model="pageindex"
        :length="get_total_pages"
        :total-visible="15"
      ></v-pagination>
    </div>
    <create-borrow-request-dialog ref="_borrowDialog"></create-borrow-request-dialog>
  </div>
</template>
<script>
import { mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import { useFolderStore } from "@/stores/folderStore.js";
import { useArchiveStore } from "@/stores/archiveStore.js";
import { useRecordStore } from "@/stores/recordStore.js";
export default {
  name: "CRecordList",
  props: ["vh", "fullscreen"],
  data() {
    return {
      headers: [
        {
          text: "",
          value: "BorrowStatus",
          align: "center",
          sortable: false,
          class: "data-table-header padding-lr3",
          width: "5",
        },
        {
          text: "文件级档号",
          value: "record_no",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "文件编号",
          value: "file_no",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题名",
          value: "name",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "顺序号",
          value: "order_index",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "页号",
          value: "page_no",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "页数",
          value: "page_count",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "备注",
          value: "note",
          align: "left",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "借阅",
          value: "jieyue",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  computed: {
    ...mapState(useUserStore, ["profile"]),
    ...mapState(useFolderStore, ["selected_folder"]),
    ...mapState(useArchiveStore, ["active_archive"]),
    ...mapState(useRecordStore, [
      "pagesize",
      "get_total_pages",
      "q",
      "totalrecords",
      "data",
      "loading",
      "active_record",
    ]),
    ...mapWritableState(useRecordStore, ["pageindex", "selected_records"]),
    myheight() {
      if (this.fullscreen) {
        return window.innerHeight + this.vh - this.vh - 50;
      } else {
        return this.vh - 90;
      }
    },
  },
  watch: {
    pageindex: {
      handler() {
        const store = useRecordStore();
        store.loadData(true);
      },
    },
  },

  methods: {
    rowClick(item, row) {
      if (this.active_record && this.active_record.id == item.id) {
        row.select(false);
        this.selected_records = [];
      } else {
        row.select(true);
      }
    },
    openBorrowDialog(type, aid, rid, title, no) {
      this.$refs._borrowDialog.openDialog(type, aid, rid, title, no);
    },
  },
};
</script>
