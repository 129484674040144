<template>
  <div style="min-height: 100%">
    <v-card>
      <v-toolbar>
        <v-toolbar-title>档案目录管理</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <v-card
      style="margin-top: 5px; overflow: hidden"
      v-resize="onResize"
      :height="containerHeight"
      flat
    >
       <div class="col-resize-container">
        <div class="drag-handle" :style="{left: (layout.left +4)+'px', height: containerHeight+'px'}" @mousedown="mdCol" @mousemove="mvCol" @mouseup="muCol">
          
          <div :class="{'col-resizer':true,'active':layout.col}"></div>
          
        </div>
       </div>
        <table style="width: 100%;" @mousemove="mvCol" @mouseup="muCol" >
          <tr>
            <td :width="layout.left" :rowspan="active_record?1:2"> 
              <c-archive-folders
                  :canedit="inrole([1])"
                  :cancreate="inrole([1])"
                  :candelete="inrole([1])"
                  :vh=" active_record?containerHeight / 2 : containerHeight+12"
                ></c-archive-folders></td>
                <td>
                  <c-archives
                  :canedit="inrole([1])"
                  :cancreate="inrole([1])"
                  :candelete="inrole([1])"
                  :canupload="inrole([1])"
                  :canimport="inrole([1])"
                  :verify="inrole([1])"
                  toolbar="true"
                  :vh="containerHeight / 2"
                ></c-archives>
                </td>
          </tr>
          <tr>
            <td v-if="active_record">
              <c-documents
                  :canedit="inrole([1])"
                  :candelete="inrole([1])"
                  :vh="containerHeight / 2"
                ></c-documents>

            </td>
            <td>

              <c-records
                  :canedit="inrole([1])"
                  :cancreate="inrole([1])"
                  :candelete="inrole([1])"
                  :canupload="inrole([1])"
                  :canimport="inrole([1])"
                  :vh="containerHeight / 2"
                ></c-records>
            </td>
          </tr>
        </table>
        
      
    </v-card>
  </div>
</template>
<script>
import { mapState } from "pinia";
import { useFolderStore } from "@/stores/folderStore.js";
import { useUserStore } from "@/stores/userStore.js";
import { useArchiveStore } from "@/stores/archiveStore.js";
import { useRecordStore } from "@/stores/recordStore.js";
import { useDocumentStore } from "@/stores/documentStore.js";
export default {
  data() {
    return {
      open: false,
      loading: false,
      folders: {
        allowedit: true,
        current: {
          activerules: [],
        },
      },
      archives: {
        allowedit: true,
        archive: {},
      },
      documents: {
        allowedit: true,
        file: {},
      },
      containerHeight: 100,
      containerWidth:100,
      layout:{
        col:null,
        ox:0,
        x:0,
        oy:0,
        y:0,
        row:null,
        left:250,
        right:250,
        upper:300,
        down:300
      },
      
    };
  },
  computed: {
    ...mapState(useFolderStore, ["selected_folder"]),
    ...mapState(useArchiveStore, ["active_archive"]),
    ...mapState(useRecordStore, ["active_record"]),
  },
  watch: {
    selected_folder: {
      handler() {
        const archiveStore = useArchiveStore();
        var opt={...archiveStore.sort_options}
        archiveStore.$reset();
        archiveStore.sort_options=opt;
        archiveStore.folderid = this.selected_folder;
        archiveStore.loadData();
      },
    },
    active_archive: {
      handler(a, b) {
        if (a && b && a.id == b.id) return;
        const store = useRecordStore();
        const documentStore = useDocumentStore();
        if (this.active_archive && this.active_archive.id) {
          store.$reset();
          store.archiveid = this.active_archive.id;
          documentStore.recordid = 0;
          store.loadData();
          documentStore.$reset();
          documentStore.archiveid = this.active_archive.id;
          documentStore.loadData();
        } else {
          store.$reset();
          documentStore.$reset();
        }
      },
    },
    active_record: {
      handler(a, b) {
        if (a && b && a.id == b.id) return;
        if (!this.active_archive) return;
        const documentStore = useDocumentStore();
        if (this.active_record && this.active_record.id) {
          documentStore.$reset();
          documentStore.archiveid = this.active_archive.id;
          documentStore.recordid = this.active_record.id;
          documentStore.loadData();
        } else {
          documentStore.$reset();
          documentStore.archiveid = this.active_archive.id;
        }
      },
    },
  },
  mounted() {
    this.onResize();
    const folderStore = useFolderStore();
    const archiveStore = useArchiveStore();
    const recordStore = useRecordStore();
    const documentStore = useDocumentStore();
    folderStore.$reset();
    archiveStore.$reset();
    recordStore.$reset();
    documentStore.$reset();
    folderStore.loadData(0);
  },
  methods: {
    onResize() {
      this.containerHeight = window.innerHeight - 135;
      this.containerWidth = window.innerWidth -200; 
    },
    resizeCol(evt){
      this.layout.right = this.containerWidth-this.layout.left;      
      event.preventDefault();
    },
    mdCol(){
      event.preventDefault();
      this.layout.col=event.currentTarget;
      this.layout.x=event.screenX;
      this.layout.ox=this.layout.left;
    },
    mvCol(){
      event.preventDefault();
      if(!this.layout.col) return;
      if(this.layout.left>=220 && this.containerWidth-this.layout.left>=800){
        this.layout.left= this.layout.ox+(event.screenX - this.layout.x)
      }
      else if(this.layout.left<220){
        this.layout.left=220;
        this.layout.col=null;
        this.layout.x=0;
      }
      else{
        this.layout.left=this.containerWidth-800;
        this.layout.col=null;
        this.layout.x=0;
      }
    },
    muCol(){
      event.preventDefault();
      this.layout.col=null;
      this.layout.x=0;
    },
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
  },
};
</script>
<style scoped>
.col-resize-container {
    height: 0px;
    position: relative;
}

.col-resize-container .drag-handle {
    margin-left: -5px;
    position: absolute;
    z-index: 5;
    width: 10px;
    cursor: col-resize;
}
.col-resize-container .drag-handle .col-resizer {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0px;
    left: 3px;
}
.col-resize-container .drag-handle:hover .col-resizer {
    border: 1px solid;
}
.col-resize-container .drag-handle .col-resizer.active {
    border: 1px solid;
}
</style>