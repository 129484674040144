<template>
  <v-dialog v-model="open" persistent max-width="1200px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card outlined>
        <v-card-title>
          <span class="headline">上传文件</span>
        </v-card-title>
        <v-container grid-list-md>
          <v-row>
            <v-col cols="5">
              <v-card height="500">
                <v-toolbar dense>
                  <v-toolbar-title>文件信息</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-container>
                  <v-flex xs12>
                    <v-text-field
                      v-model="data.name"
                      :rules="[validateFileName]"
                      label="文件名"
                      :hint="hint_name"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="data.charger" label="责任人"></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-textarea
                      name="hack-chrome"
                      label="备注"
                      :rows="3"
                      v-model="data.note"
                      autocomplete="false"
                    ></v-textarea>
                  </v-flex>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="7">
              <c-multi-file-selector
                ref="_file_list"
                mh="500"
                @filechange="processFileNames"
              ></c-multi-file-selector>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="open = false">取消</v-btn>
          <v-btn color="warning" :disabled="!valid" @click="createOrEdit()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { useDocumentStore } from "@/stores/documentStore.js";
export default {
  name: "ArchiveDocumentEditDialog",
  props: [],
  data: () => ({
    open: false,
    valid: false,
    hint_name: "",
    data: {
      archiveid: 0,
      recordid: 0,
      name: "",
      archived: false,
      note: "",
      charger: "",
    },
    rules: {
      file: [(v) => !!v || "请选择上传文件"],

      charger: [
        (v) => !!v || "请填写负责人",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) || "负责人长度必须在2到50个字符之间",
      ],
      pages: [(v) => /^\d*$/.test(v || "0") || "请填写数字"],
    },
  }),
  computed: {},
  watch: {
    "data.name": function () {
      if (this.$refs.form.validate()) {
        this.processFileNames();
      }
    },
  },
  methods: {
    openDialog(archiveid, recordid) {
      if (this.$refs._file_list) {
        this.$refs._file_list.clearFiles();
      }
      this.data = {
        archiveid: archiveid,
        recordid: recordid,
        name: "",
        archived: false,
        note: "",
        charger: "",
      };
      this.open = true;
    },
    validateFileName(n) {
      if (!n) {
        this.hint_name = "留空则为原始文件名";
        return true;
      }
      if (!/^\{\d+:\d+}$/.test(n)) {
        if (!this.$refs._file_list.files || this.$refs._file_list.files.length <= 1) {
          return true;
        }
        if (this.$refs._file_list.files && this.$refs._file_list.files.length > 1) {
          this.hint_name = "例如：{4:1}, 则文件名为0001,0002...";
          return "多文件上传须使用{长度:起始号}来命名文件";
        }
      }
      return true;
    },
    processFileNames() {
      if (
        !this.data.name ||
        !this.$refs._file_list.files ||
        !this.$refs._file_list.files.length
      )
        return;
      if (/^\{\d+:\d+}$/.test(this.data.name)) {
        var seg = this.data.name.replace("{", "").replace("}", "").split(":");
        var length = seg[0] * 1;
        var idx = seg[1] * 1;
        for (var i = 0; i < this.$refs._file_list.files.length; i++) {
          var n = idx + i;
          var ext = "";
          if (this.$refs._file_list.files[i].name.lastIndexOf(".") >= 0) {
            ext = this.$refs._file_list.files[i].name.substr(
              this.$refs._file_list.files[i].name.lastIndexOf(".")
            );
          }
          this.$refs._file_list.files[i].name = this.$g.fixStringLn(n + "", length) + ext;
        }
      } else {
        this.$refs._file_list.files[0].name = this.data.name;
      }
    },
    createOrEdit() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs._file_list.files || !this.$refs._file_list.files.length) {
        this.$ns.cast("snack", {
          text: "请至少选择一个文件上传",
          color: "error",
        });
        return;
      }
      const store = useDocumentStore();
      var req = store.batchUpload(this.data, this.$refs._file_list.files);
      req
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            store.loadData();
            this.open = false;
          },
          (x) => {}
        );
    },
    getFile(evt) {
      var file = event.target.files;
      if (file && file.length > 0) {
        this.config.file.name = file[0].name;
      }
    },
  },
};
</script>
