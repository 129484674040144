<template>
  <div>
    <v-card outlined>
      <v-toolbar
        color="blue-grey  lighten-5"
        dense
      >
        <v-toolbar-title class="text-subtitle-1">文件</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              icon
              v-on="on"
              :disabled="!data || !data.length || !view_document_permission"
              @click="downloadZip"
            >
              <v-icon small>download</v-icon>
            </v-btn>
          </template>
          <span>打包下载文件</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="canedit"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="green"
              icon
              v-on="on"
              @click="openEditDialog"
              :disabled="!active_document || !archive_permission"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>编辑文件</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="candelete"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="error"
              icon
              v-on="on"
              @click="deleteFile"
              :disabled="!active_document || !archive_permission"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>删除文件</span>
        </v-tooltip>
      </v-toolbar>
      <c-document-list
        ref="_document_list"
        :vh="vh"
      ></c-document-list>
      <archive-document-edit-dialog ref="_document_editor"></archive-document-edit-dialog>
      <document-view-dialog ref="_document_viewer"></document-view-dialog>
    </v-card>
  </div>
</template>
<script>
import fileDownload from "js-file-download";
import Axios from "axios";
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import { useDocumentStore } from "@/stores/documentStore.js";
import { useArchiveStore } from "@/stores/archiveStore.js";
export default {
  name: "CDocuments",
  props: ["vh", "canedit", "candelete"],
  data() {
    return {
      q: "",
      dialogs: {
        edit: {
          type: 0,
          open: false,
          file: {},
        },
      },
    };
  },
  computed: {
    ...mapState(useUserStore, ["profile", "token"]),
    ...mapState(useDocumentStore, ["active_document", "data"]),
    ...mapState(useArchiveStore, [
      "archive_permission",
      "view_document_permission",
    ]),
    apiroot() {
      return process.env.VUE_APP_API;
    },
  },
  methods: {
    openEditDialog() {
      this.$refs._document_editor.openDialog({ ...this.active_document });
    },
    openDocumentViewDialog() {
      var doc = null;
      if (this.active_document) {
        doc = { ...this.active_document };
      } else {
        doc = { ...this.data[0] };
      }
      this.$refs._document_viewer.openDialog(doc);
    },
    downloadZip() {
      var doc = null;
      if (this.active_document) {
        doc = { ...this.active_document };
      } else {
        doc = { ...this.data[0] };
      }
      this.$ns.cast("lock", true);
      Axios({
        method: "get",
        url: `${this.apiroot}archivedocuments/downloadzip?aid=${doc.archiveid}&rid=${doc.recordid}`,
        headers: {
          Authorization: `Bearer ${this.token.Token}`,
        },
        responseType: "blob",
      })
        .then((x) => {
          var filename = x.request.getResponseHeader("pd-name");
          if (!filename) {
            this.$ns.cast("snack", {
              text: "没有权限下载文档",
              color: "error",
            });
          } else {
            fileDownload(x.data, decodeURI(filename));
          }
        })
        .finally(() => this.$ns.cast("lock", false));
    },
    deleteFile() {
      this.$confirm("确定要删除这个文件吗? ").then((x) => {
        if (x) {
          const store = useDocumentStore();
          store.delete();
          store.selected_documents = [];
        }
      });
    },
  },
};
</script>
