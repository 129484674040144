<template>
  <div :style="{ height: vh - 65 + 'px', overflow: 'auto' }">
    <v-treeview
      v-if="data && data.length"
      class="folder-tree"
      hoverable
      :open.sync="openids"
      activatable
      :active.sync="active_folders"
      :items="data"
      dense
      active-class="folder-tree-active"
    ></v-treeview>
  </div>
</template>
<script>
//operate tree selection:https://codepen.io/denisemenov/pen/GRRQWEb,  child: https://codepen.io/ellisdod/pen/MWwqYBB,
import { mapState, mapWritableState } from "pinia";
import { useFolderStore } from "@/stores/folderStore.js";
export default {
  name: "CFolderTree",
  props: ["vh"],
  data() {
    return {
      loading: false,
      editingItem: {
        rules: [],
      },
    };
  },
  computed: {
    ...mapState(useFolderStore, ["data"]),
    ...mapWritableState(useFolderStore, ["active_folders","openids"]),
  },

  methods: {},
};
</script>
