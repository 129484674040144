import Vue from "vue";
import "babel-polyfill";
import Landing from "./Landing.vue";
import vuetify from "./plugins/vuetify";
import VueResource from "vue-resource";
import VueLocalStorage from "vue-localstorage";
import VuetifyConfirm from "vuetify-confirm";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import notificationPlugin from "./plugins/notification";
import httpClientPlugin from "./plugins/httpclient";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import "./assets/main.css";
import leafletPlugin from "./plugins/leaflet";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import L from "leaflet";
window.L = L;
import { router, pinia, getUserStore } from "./routes";

Vue.config.productionTip = false;
Object.defineProperty(TiptapVuetifyPlugin, "vuetifyLang", {
  get: function () {
    return "zh";
  },
});
Vue.use(VueViewer, {
  defaultOptions: {
    zIndex: 9999,
    navbar: false,
    toolbar: false,
    rotatable: false,
    fullscreen: true,
    keyboard: false,
    transition: true,
    scalable: true,
    inline: false,
    button: true,
    title: true,
    tooltip: true,
    movable: true,
  },
});
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "md",
});
//https://github.com/yariksav/vuetify-confirm
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "确定",
  buttonFalseText: "取消",
  color: "warning",
  icon: "warning",
  title: "注意!",
  width: 350,
  property: "$confirm",
});

//https://github.com/brockpetrie/vue-moment
const moment = require("moment");
require("moment/locale/zh-cn");
window.m = moment;
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(leafletPlugin);
Vue.use(notificationPlugin);
Vue.use(VueResource);
Vue.use(VueLocalStorage, {
  name: "ls",
  bind: true,
});

Vue.http.options.root = process.env.VUE_APP_API;
Vue.http.interceptors.push(function (r) {
  r.headers.set("Content-Type", "application/json");
  r.headers.set("Accept", "application/json");
  const userStore = getUserStore();
  const tk = userStore.userToken;
  if (tk) {
    r.headers.set("Authorization", `Bearer ${tk.Token}`);
  }
});
Vue.use(httpClientPlugin);
const requireComponent = require.context(
  "./components",
  true,
  /[A-Z]\w+\.vue$/
);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/.+\/([^/]*)\.vue$/, "$1"))
  );
  var seg = fileName.split("/");
  var prefix = "";
  for (var i = 1; i < seg.length; i++) {
    if (seg[i] == "." || seg[i].endsWith(".vue")) {
      continue;
    }
    prefix = seg[i][0].toUpperCase() + prefix;
  }
  Vue.component(
    prefix + componentName,
    componentConfig.default || componentConfig
  );
});
//Vue.component('vue-tribute', VueTribute)
new Vue({
  vuetify,
  router,
  pinia,
  render: (h) => h(Landing),
}).$mount("#app");
