<template>
  <v-dialog v-model="open" persistent max-width="800px">
    <v-card outlined>
      <v-card-title>
        <span class="headline">选择实体分类</span>
      </v-card-title>
      <v-card-text>
        <div :style="{ height: 400 + 'px', overflow: 'auto' }">
          <v-treeview
            v-if="data && data.length"
            class="folder-tree"
            hoverable
            open-all
            activatable
            :active.sync="active_categories"
            :items="data"
            dense
            active-class="folder-tree-active"
          ></v-treeview>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="open = false">取消</v-btn>
        <v-btn color="warning" :disabled="!selected_category" @click="done">确定</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useCategoryStore } from "@/stores/categoryStore.js";
export default {
  name: "CategoryTreeSelectDialog",
  props: ["cateno"],
  data: () => ({
    open: false,
    height: 700,
    folders: {
      current: {
        rules: [],
      },
    },
    archives: {
      archive: {},
    },
  }),
  computed: {
    ...mapState(useCategoryStore, ["data", "selected_category"]),
    ...mapWritableState(useCategoryStore, ["active_categories"]),
  },
  methods: {
    openDialog() {
      const cateStore = useCategoryStore();
      cateStore.$reset();
      cateStore.loadData();
      this.open = true;
    },
    done() {
      const cateStore = useCategoryStore();
      var current = cateStore.getSelectedNodeCopy();
      this.$emit("update:cateno", current.value);
      this.open = false;
    },
  },
};
</script>
