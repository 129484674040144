<template>
  <v-dialog
    v-model="open"
    max-width="800px"
    max-height="600px"
  >
    <v-card
      outlined
      style="height: 70%"
      tile
    >
      <v-toolbar
        color="blue lighten-4"
        dense
      >
        <v-toolbar-title class="text-subtitle-1">查看档案
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
        dense
      >
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="15"
        ></v-pagination>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="open = false"
        >关闭</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ArchiveRequestRecordsDialog",
  data() {
    return {
      loading: false,
      open: false,
      id: 0,
      title: "",
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      data: {},
      headers: [
        {
          text: "顺序号",
          value: "order_index",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "80px",
        },
        {
          text: "文件编号",
          value: "file_no",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题名",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "页数",
          value: "page_count",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "50px",
        },
        {
          text: "实体分类号",
          value: "entity_catelog_no",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "生态环境要素类目",
          value: "huanjingyaosuleimu",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "130px",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    openDialog(id, title) {
      this.id = id;
      this.title = title;
      this.open = true;
      this.data = {};
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetchData();
      }
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `archive/requests/records?requestid=${this.id}&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {
            this.loading = false;
          }
        );
    },
  },
};
</script>
