<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="data.name"
                :rules="rules.name"
                :counter="50"
                maxlength="50"
                label="项目名称"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="data.department"
                :rules="rules.department"
                :counter="50"
                maxlength="50"
                label="负责部门"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="data.charger"
                :rules="rules.charger"
                :counter="50"
                label="负责人"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="data.leader"
                :rules="rules.leader"
                :counter="50"
                label="主管领导"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-select
                v-model="data.type"
                :items="projectTypeList"
                :rules="rules.type"
                item-text="name"
                item-value="name"
                label="项目类型"
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-menu
                ref="startDialog"
                v-model="startDialog"
                persistent
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatedDate"
                    label="开始日期"
                    prepend-icon="event"
                    :rules="rules.start"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.start"
                  no-title
                  @input="startDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="data.term"
                :rules="rules.term"
                label="项目周期(月)"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="data.fundsource"
                :rules="rules.fundsource"
                label="经费来源"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="data.budget"
                :rules="rules.budget"
                label="预算(元)"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="data.lat"
                :rules="rules.lat"
                label="纬度(wgs84)"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="data.lon"
                :rules="rules.lon"
                label="经度(wgs84)"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                name="hack-chrome"
                label="备注"
                :rows="3"
                v-model="data.note"
                autocomplete="false"
                :counter="500"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)">取消</v-btn>
          <v-btn color="warning" :disabled="!valid" @click="createOrEditProject()"
            >确定</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "ProjectEditDialog",
  props: ["data", "open"],
  data: () => ({
    valid: false,
    startDialog: false,

    rules: {
      name: [
        (v) => !!v || "请填写项目名称",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) || "项目名称长度必须在2到50个字符之间",
      ],
      department: [
        (v) => !!v || "请填写负责部门",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) || "负责部门长度必须在2到50个字符之间",
      ],
      charger: [
        (v) => !!v || "请填写负责人",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) || "负责人长度必须在2到50个字符之间",
      ],
      leader: [
        (v) => !!v || "请填写主管领导",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) || "主管领导长度必须在2到50个字符之间",
      ],
      start: [(v) => !!v || "请选择开始日期"],
      type: [(v) => !!v || "请选择项目类型"],
      term: [
        (v) => !!v || "请填写项目周期(月)",
        (v) => (v && v * 1 > 0 && /^\d+$/.test(v)) || "请填写大于0的整数",
      ],
      fundsource: [(v) => !!v || "请填写经费来源"],
      budget: [
        (v) => !!v || "请填写预算(元)",
        (v) => (v && v * 1 > 0) || "请填写大于0的数",
      ],
      lat: [(v) => !v || !isNaN(v * 1) || "请填写正确的纬度"],
      lon: [(v) => !v || !isNaN(v * 1) || "请填写正确的经度"],
    },
  }),
  computed: {
    ...mapState(useUserStore, ["enums"]),
    title() {
      if (this.data && this.data.projectid) {
        return "修改项目";
      }
      return "新建项目";
    },
    projectTypeList() {
      if (this.enums && this.enums.ProjectTypes) {
        return this.enums.ProjectTypes;
      }
      return [];
    },
    formatedDate() {
      return this.data && this.data.start
        ? this.$moment(this.data.start, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD")
        : "";
    },
  },
  methods: {
    createOrEditProject() {
      if (!this.$refs.form.validate()) return;
      if (this.data && this.data.projectid) {
        this.$hc
          .req()
          .put(`projects`, { ...this.data })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .post(`projects`, { ...this.data })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
