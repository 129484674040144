<template>
  <div style="min-height: 100%">
    <v-container fluid class="minhight100">
      <v-row class="minhight100">
        <v-col cols="12" style="padding-top: 0px">
          <v-card
            v-resize="onResize"
            style="margin-top: 20px; overflow: hidden"
            :height="containerHeight"
          >
            <v-toolbar color="blue-grey  lighten-5" dense>
              <v-toolbar-title class="text-subtitle-1">实体分类</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" icon v-on="on" @click="openCreateDialog">
                    <v-icon>create_new_folder</v-icon>
                  </v-btn>
                </template>
                <span>新建实体分类</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="green"
                    icon
                    v-on="on"
                    :disabled="!selected_category"
                    @click="openEditDialog"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>编辑实体分类</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="error"
                    icon
                    v-on="on"
                    :disabled="!selected_category"
                    @click="deleteCategory"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>
                <span>删除实体分类</span>
              </v-tooltip>
            </v-toolbar>
            <div :style="{ height: containerHeight + 'px', overflow: 'auto' }">
              <v-treeview
                v-if="data && data.length"
                class="folder-tree"
                hoverable
                open-all
                activatable
                :active.sync="active_categories"
                :items="data"
                dense
                active-class="folder-tree-active"
              ></v-treeview>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <category-edit-dialog ref="_category" @success="onSuccess"></category-edit-dialog>
  </div>
</template>
<script>
import { mapState, mapWritableState } from "pinia";
import { useCategoryStore } from "@/stores/categoryStore.js";
export default {
  data() {
    return {
      containerHeight: 100,
    };
  },
  mounted() {
    this.onResize();
    const cateStore = useCategoryStore();
    cateStore.active_categories = [];
    cateStore.loadData();
  },
  computed: {
    ...mapState(useCategoryStore, ["data", "selected_category"]),
    ...mapWritableState(useCategoryStore, ["active_categories"]),
  },
  methods: {
    onResize() {
      this.containerHeight = window.innerHeight - 148;
    },
    openCreateDialog() {
      this.$refs._category.openDialog(null);
    },
    openEditDialog() {
      const cateStore = useCategoryStore();
      var current = cateStore.getSelectedNodeCopy();
      this.$refs._category.openDialog(current);
    },
    onSuccess(vm) {
      const cateStore = useCategoryStore();
      cateStore.createOrUpdate(vm);
    },
    deleteCategory() {
      this.$confirm("确定要删除这个实体分类吗?").then((x) => {
        if (x) {
          const cateStore = useCategoryStore();
          cateStore.delete();
        }
      });
    },
    esc() {},
  },
};
</script>
