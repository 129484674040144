import { defineStore } from "pinia";
import Vue from "vue";

export const useDocumentStore = defineStore("documents", {
  state: () => ({
    archiveid: 0,
    recordid: 0,
    selected_documents: [],
    data: [],
    loading: false,
    pagesize: 10,
    pageindex: 1,
    totalrecords: 0,
    q: "",
  }),
  getters: {
    get_total_pages: (state) => {
      if (state.totalrecords) {
        return Math.ceil(state.totalrecords / state.pagesize);
      }
      return 0;
    },
    active_document: (state) => {
      if (state.selected_documents && state.selected_documents.length) {
        return state.selected_documents[0];
      }
      return null;
    },
  },
  actions: {
    loadData(clear, old) {
      this.loading = true;
      if (clear) {
        this.selected_documents = [];
      }
      if (this.archiveid || this.recordid) {
        Vue.hc
          .req()
          .get(
            `archivedocuments?aid=${this.archiveid}&rid=${
              this.recordid
            }&pagesize=${this.pagesize}&pageindex=${this.pageindex}&q=${
              this.q || ""
            }`
          )
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.data = x.Data.Payload;
              this.totalrecords = x.Data.Total;
              if (old) {
                for (var i = 0; i < this.data.length; i++) {
                  if (this.data[i].id == old.id) {
                    this.selected_documents = [];
                    this.selected_documents = [this.data[i]];
                  }
                }
              }
              this.loading = false;
            },
            (x) => {
              this.loading = false;
            }
          );
      } else {
        this.$reset();
      }
    },
    batchUpload(vm, files) {
      var req = Vue.hc.req();
      for (var i = 0; i < files.length; i++) {
        var f = files[i];
        var fe = new FormData();
        fe.append("aid", vm.archiveid || 0);
        fe.append("rid", vm.recordid || 0);
        fe.append("name", f.name || f.oriname);
        fe.append("archived", vm.archived || false);
        fe.append("charger", vm.charger || "");
        fe.append("note", vm.note || "");
        fe.append("file", f.file);
        req = req.post(`archivedocuments`, fe);
      }
      return req;
    },
    delete() {
      this.loading = true;
      Vue.hc
        .req()
        .delete(
          `archivedocuments?did=${this.active_document.id}&aid=${
            this.archiveid || 0
          }&rid=${this.recordid || 0}`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loadData();
          },
          (x) => {
            this.loading = false;
          }
        );
    },
    preview(id, aid, rid) {
      return Vue.hc
        .req()
        .get(
          `archivedocuments/download?type=preview&id=${id}&aid=${aid}&rid=${rid}`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        });
    },
    update(vm) {
      var fe = new FormData();
      fe.append("name", vm.name || "");
      fe.append("charger", vm.charger || "");
      fe.append("note", vm.note || "");
      fe.append("id", vm.id);
      if (vm.file) {
        fe.append("file", vm.file);
      }
      return Vue.hc.req().put(`archivedocuments`, fe).go({
        lock: true,
        toastError: true,
        toastSuccess: "",
      });
    },
  },
});
