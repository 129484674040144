<template>
  <v-dialog v-model="open" persistent max-width="1200px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card outlined>
        <v-card-title>
          <span class="headline">编辑文件</span>
        </v-card-title>
        <v-container grid-list-md>
          <v-row>
            <v-col cols="5">
              <v-card height="500">
                <v-toolbar dense>
                  <v-toolbar-title>文件信息</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-container>
                  <v-flex xs12>
                    <v-text-field
                      v-model="data.name"
                      :rules="rules.name"
                      label="文件名"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="data.charger" label="责任人"></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-textarea
                      name="hack-chrome"
                      label="备注"
                      :rows="3"
                      v-model="data.note"
                      autocomplete="false"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn @click="chooseFiles">重新选择文件</v-btn>
                    <input
                      type="file"
                      ref="_file_input"
                      style="display: none"
                      @change="getFile"
                    />
                  </v-flex>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="7">
              <v-img contain :src="data.imageData"></v-img>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="open = false">取消</v-btn>
          <v-btn color="warning" :disabled="!valid" @click="createOrEdit()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { useDocumentStore } from "@/stores/documentStore.js";
export default {
  name: "ArchiveDocumentEditDialog",
  props: [],
  data: () => ({
    valid: false,
    open: false,
    data: {
      name: "",
      charger: "",
      note: "",
      imageData: "",
      file: null,
    },
    rules: {
      name: [
        (v) => !!v || "请填写文件名称",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) || "文档名称长度必须在2到50个字符之间",
      ],
    },
  }),
  computed: {},
  methods: {
    openDialog(vm) {
      this.data = { ...vm };
      const store = useDocumentStore();
      store.preview(vm.id, vm.archiveid, vm.recordid).subscribe(
        (x) => {
          this.data.imageData = `data:${x.Data.mime};base64,${x.Data.base64}`;
          this.$forceUpdate();
        },
        (x) => {}
      );
      this.open = true;
    },
    chooseFiles() {
      this.$refs._file_input.click();
    },
    createOrEdit() {
      if (!this.$refs.form.validate()) return;
      const store = useDocumentStore();
      store.update(this.data).subscribe(
        (x) => {
          store.loadData(false, this.data);
          this.open = false;
        },
        (x) => {}
      );
    },
    getFile(evt) {
      var file = event.target.files;
      if (file && file.length > 0) {
        this.data.name = file[0].name;
        this.data.file = file[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.data.imageData = e.target.result;
          this.$forceUpdate();
        };
        reader.readAsDataURL(file[0]);
      }
    },
  },
};
</script>
