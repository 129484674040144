<template>
  <div>
    <v-card outlined>
      <v-toolbar
        color="blue-grey  lighten-5"
        dense
      >
        <v-toolbar-title>入档申请</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          dense
          hide-details="true"
          item-text="text"
          item-value="id"
          :items="requestStatus"
          v-model="status"
          label="申请状态"
          class="ft1rem"
        ></v-select>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
        dense
      >
        <template v-slot:item.op="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="blue"
                small
                icon
                v-on="on"
                @click.native.stop="openRecordsDialog(props.item.id)"
              >
                <v-icon small>remove_red_eye</v-icon>
              </v-btn>
            </template>
            <span>查看档案</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                :disabled="props.item.status!=0"
                @click.native.stop="changeStatus(props.item.id,1)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>撤销申请</span>
          </v-tooltip>
        </template>
        <template
          v-slot:item.admin="props"
          v-if="inrole([1])"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="red"
                small
                icon
                v-on="on"
                :disabled="props.item.status!=0"
                @click.native.stop="changeStatus(props.item.id,3)"
              >
                <v-icon small>do_not_disturb_on</v-icon>
              </v-btn>
            </template>
            <span>驳回请求</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="green"
                small
                icon
                v-on="on"
                :disabled="props.item.status!=0"
                @click.native.stop="changeStatus(props.item.id,2)"
              >
                <v-icon small>check_circle</v-icon>
              </v-btn>
            </template>
            <span>确认入档</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="15"
        ></v-pagination>
      </div>
    </v-card>
    <archive-request-records-dialog ref="_ref_records"></archive-request-records-dialog>
  </div>
</template>
<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  data() {
    return {
      loading: true,
      status: -1,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      requestStatus: [
        { id: -1, text: "所有" },
        { id: 0, text: "未处理" },
        { id: 1, text: "撤销" },
        { id: 2, text: "完成" },
        { id: 3, text: "驳回" },
      ],
      data: {},
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "50px",
        },
        {
          text: "申请状态",
          value: "status_str",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "档案目录",
          value: "folder",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "档号",
          value: "archive_no",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题名",
          value: "title",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "",
          value: "admin",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    status(val) {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState(useUserStore, ["enums"]),

    borrowRequestStatus() {
      if (this.enums && this.enums.BorrowRequestStatus) {
        return this.enums.BorrowRequestStatus;
      }
      return [];
    },
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `archive/requests?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}&type=my&status=${this.status}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {
            this.loading = false;
          }
        );
    },
    openRecordsDialog(id, title) {
      this.$refs._ref_records.openDialog(id, title);
    },
    changeStatus(id, status) {
      var text = "";
      if (status == 1) {
        text = "撤销申请";
      } else if (status == 2) {
        text = "确认入档";
      } else if (status == 3) {
        text = "驳回申请";
      }
      this.$confirm(`确定要${text}吗? `).then((x) => {
        if (x) {
          this.loading = true;
          this.$hc
            .req()
            .put(`archive/requests/status`, { id: id, status: status })
            .go({
              lock: true,
              toastError: false,
              toastSuccess: "",
            })
            .subscribe(
              (x) => {
                this.loading = false;
                this.fetchData();
              },
              (x) => {
                this.loading = false;
              }
            );
        }
      });
    },
  },
};
</script>
