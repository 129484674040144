<template>
  <v-sheet>
    <v-card>
      <v-card-title>
        <v-text-field
          label="搜索子项目"
          v-model="q"
          dense
          flat
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          v-if="inrole([1, 3])"
          @click="
            current = {};
            dialog = true;
          "
          >新建子项目</v-btn
        >
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
        dense
      >
        <template v-slot:item.createdtime="props">{{
          props.item.createdtime | moment("YYYY-MM-DD")
        }}</template>
        <template v-slot:item.estimate="props">{{
          props.item.estimate | moment("YYYY-MM-DD")
        }}</template>
        <template v-slot:item.op="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                small
                icon
                v-on="on"
                :to="`/subprojects/${props.item.subprojectid}/details`"
              >
                <v-icon small>remove_red_eye</v-icon>
              </v-btn>
            </template>
            <span>查看子项目</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              inrole([1, 3]) || (profile.role == 4 && props.item.createdby == profile.id)
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="orange"
                small
                icon
                v-on="on"
                @click="
                  current = { ...props.item };
                  dialog = true;
                "
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>修改子项目</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              inrole([1, 3]) || (uprofile.role == 4 && props.item.createdby == profile.id)
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                v-if="props.item.accountid != 1"
                @click.native.stop="deleteData(props.item.subprojectid)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>删除子项目</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有子项目</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
    <sub-project-edit-dialog
      :data="current"
      :open.sync="dialog"
      :projectid="projectid"
      @success="fetchData"
    ></sub-project-edit-dialog>
  </v-sheet>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "SubProjectTab",
  props: ["projectid"],
  data() {
    return {
      loading: true,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      data: {},
      current: {},
      dialog: false,
      headers: [
        {
          text: "ID",
          value: "subprojectid",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "子项目名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "状态",
          value: "status",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "预计完成时间",
          value: "estimate",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "创建时间",
          value: "createdtime",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "备注",
          value: "note",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    ...mapState(useUserStore, ["profile"]),
  },
  methods: {
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetchData();
      }
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `subprojects?pid=${this.projectid}&pagesize=${
            this.pagination.rowsPerPage
          }&pageindex=${this.pagination.page}&q=${this.q || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteData(id) {
      this.$confirm("确定要删除这个子项目吗?").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`subprojects?spid=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
