import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import LChinaTilelayer from 'vue2-leaflet-chinatilelayer'
require('proj4leaflet')
export default {
    install: (Vue) => {
        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        });
        L.CRS.Baidu = new L.Proj.CRS('EPSG:900913', '+proj=merc +a=6378206 +b=6356584.314245179 +lat_ts=0.0 +lon_0=0.0 +x_0=0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs', {
            resolutions: function () {
                var level = 19
                var res = [];
                res[0] = Math.pow(2, 18);
                for (var i = 1; i < level; i++) {
                    res[i] = Math.pow(2, (18 - i))
                }
                return res;
            }(),
            origin: [0, 0],
            bounds: L.bounds([20037508.342789244, 0], [0, 20037508.342789244])
        });
        Vue['l'] = L;
        Vue.prototype.$l = L;
        Vue.component('l-map', LMap);
        Vue.component('l-popup', LPopup);
        Vue.component('l-icon', LIcon);
        Vue.component('l-tile-layer', LTileLayer);
        Vue.component('l-marker', LMarker);
        Vue.component('l-china-tilelayer', LChinaTilelayer);
    }
}