<template>
  <div>
    <v-card outlined>
      <v-toolbar
        color="blue-grey  lighten-5"
        dense
      >
        <v-toolbar-title>我的借阅申请</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          dense
          hide-details="true"
          item-text="text"
          item-value="id"
          :items="borrowStatus"
          v-model="status"
          label="申请状态"
          class="ft1rem"
        ></v-select>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
        dense
      >
        <template v-slot:item.return_date="props">
          <span :class="{'red--text':(props.item.status==2 && props.item.expired)}">{{props.item.return_date}}</span>
        </template>
        <template v-slot:item.charger_dep_name="props">
          <span :class="{'green--text text--darken-2':(props.item.charger_dep_name && props.item.confirm_dep==1),'orange--text text--darken-2':(props.item.charger_dep_name && props.item.confirm_dep==-1)}">{{props.item.charger_dep_name ||''}}</span>
        </template>
        <template v-slot:item.charger_tech_name="props">
          <span :class="{'green--text text--darken-2':(props.item.charger_tech_name && props.item.confirm_tech==1),'orange--text text--darken-2':(props.item.charger_tech_name && props.item.confirm_tech==-1)}">{{props.item.charger_tech_name ||''}}</span>
        </template>
        <template v-slot:item.charger_quantity_name="props">
          <span :class="{'green--text text--darken-2':(props.item.charger_quantity_name && props.item.confirm_quantity==1),'orange--text text--darken-2':(props.item.charger_quantity_name && props.item.confirm_quantity==-1)}">{{props.item.charger_quantity_name ||''}}</span>
        </template>
        <template v-slot:item.leader_name="props">
          <span :class="{'green--text text--darken-2':(props.item.leader_name && props.item.confirm_leader==1),'orange--text text--darken-2':(props.item.leader_name && props.item.confirm_leader==-1)}">{{props.item.leader_name ||''}}</span>
        </template>
        <template v-slot:item.op="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="success"
                small
                icon
                v-on="on"
                :disabled="props.item.status!=2"
                @click.native.stop="changeStatus(props.item.id,3)"
              >
                <v-icon small>assignment_return</v-icon>
              </v-btn>
            </template>
            <span>申请归还</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                :disabled="props.item.status!=0"
                @click.native.stop="changeStatus(props.item.id,6)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>撤销申请</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="15"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  data() {
    return {
      loading: true,
      status: -1,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      borrowStatus: [
        { id: -1, text: "所有" },
        { id: 0, text: "未处理" },
        { id: 2, text: "借出" },
        { id: 3, text: "归还申请" },
        { id: 5, text: "完成" },
        { id: 6, text: "撤销" },
      ],
      data: {},
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "申请状态",
          value: "status_str",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "借阅类别",
          value: "doc_type",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "档号",
          value: "doc_no",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题名",
          value: "doc_title",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "借阅时间",
          value: "borrow_date",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "归还时间",
          value: "return_date",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "部门负责人",
          value: "charger_dep_name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "技术负责人",
          value: "charger_tech_name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "质量负责人",
          value: "charger_quantity_name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "中心领导",
          value: "leader_name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    status(val) {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState(useUserStore, ["enums"]),

    borrowRequestStatus() {
      if (this.enums && this.enums.BorrowRequestStatus) {
        return this.enums.BorrowRequestStatus;
      }
      return [];
    },
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `borrow/requests?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}&type=my&status=${this.status}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {
            this.loading = false;
          }
        );
    },
    changeStatus(id, status) {
      this.$confirm(`确定要${status == 3 ? "申请归还" : "撤销申请"}吗? `).then(
        (x) => {
          if (x) {
            this.loading = true;
            this.$hc
              .req()
              .put(`borrow/requests/status`, { id: id, status: status })
              .go({
                lock: true,
                toastError: false,
                toastSuccess: "",
              })
              .subscribe(
                (x) => {
                  this.loading = false;
                  this.fetchData();
                },
                (x) => {
                  this.loading = false;
                }
              );
          }
        }
      );
    },
  },
};
</script>
