<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>系统字典管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field label="类别" v-model="group" dense flat hide-details></v-text-field>
        <v-text-field label="关键字" v-model="q" dense flat hide-details></v-text-field>
        <v-btn color="default" @click="search" small>搜索</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="profile.role == 1" @click="openEditDialog(null)"
          >新建字典条目</v-btn
        >
      </v-toolbar>
    </v-card>

    <v-card style="margin-top: 20px">
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
      >
        <template v-slot:item.op="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="orange"
                small
                icon
                v-on="on"
                @click="openEditDialog(props.item)"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>修改</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                v-if="props.item.accountid != 1"
                @click.native.stop="deleteItem(props.item.id)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>删除</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有数据</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>

    <v-dialog v-model="dialogEdit.open" persistent max-width="600px">
      <v-form
        ref="editForm"
        v-model="dialogEdit.info.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogEdit.info.title }}</span>
          </v-card-title>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="dialogEdit.info.data.groupname"
                  :rules="rules.group"
                  :counter="50"
                  maxlength="50"
                  label="类别"
                ></v-text-field>
                <v-text-field
                  v-model="dialogEdit.info.data.ord"
                  :rules="rules.ord"
                  label="显示顺序"
                ></v-text-field>
                <v-text-field
                  v-model="dialogEdit.info.data.name"
                  :rules="rules.name"
                  :counter="50"
                  maxlength="50"
                  label="名称"
                ></v-text-field>

                <v-text-field
                  v-model="dialogEdit.info.data.value"
                  :rules="rules.value"
                  :counter="50"
                  label="值"
                ></v-text-field>

                <v-textarea
                  name="hack-chrome"
                  label="备注"
                  :rows="3"
                  v-model="dialogEdit.info.data.note"
                  autocomplete="false"
                  :counter="500"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogEdit.open = false">取消</v-btn>
            <v-btn
              color="warning"
              :disabled="!dialogEdit.info.valid"
              @click="createOrEdit(dialogEdit.info.data)"
              >确定</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  data() {
    return {
      loading: true,
      q: "",
      group: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      dialogEdit: {
        open: false,
        info: {
          valid: false,
          data: {},
        },
      },
      data: {},
      rules: {
        group: [(v) => !!v || "请填写类别"],
        name: [
          (v) => !!v || "请填写名称",
          (v) => (v && v.length <= 50) || "姓名长度必须少于50个字符",
        ],
        value: [(v) => !!v || "请填写值"],
        ord: [(v) => /\d+/.test(v) || "请填写数字"],
      },
      headers: [
        {
          text: "类别",
          value: "groupname",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "显示顺序",
          value: "ord",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "值",
          value: "value",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "备注",
          value: "note",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    ...mapState(useUserStore, ["profile"]),
  },
  methods: {
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetchData();
      }
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `systemdata?type=systemdictionary&pagesize=${
            this.pagination.rowsPerPage
          }&pageindex=${this.pagination.page}&q=${this.q || ""}&group=${this.group || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteItem(id) {
      this.$confirm("确定要删除这个用户吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`systemdata?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
    openEditDialog(data) {
      if (data) {
        this.dialogEdit.info.new = false;
        this.dialogEdit.info.title = "修改字典条目";
        this.dialogEdit.info.data = { ...data };
      } else {
        this.dialogEdit.info.new = true;
        this.dialogEdit.info.title = "新建字典条目";
        this.dialogEdit.info.data = {
          groupname: this.group,
        };
      }

      this.dialogEdit.open = true;
    },

    createOrEdit(data) {
      if (!this.$refs.editForm.validate()) return;
      if (this.dialogEdit.info.new) {
        this.$hc
          .req()
          .post(`systemdata`, {
            name: data.name,
            type: "systemdictionary",
            groupname: data.groupname,
            value: data.value,
            note: data.note,
            ord: data.ord,
          })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchData();
              this.dialogEdit.open = false;
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .put(`systemdata`, {
            id: data.id,
            name: data.name,
            type: "systemdictionary",
            groupname: data.groupname,
            value: data.value,
            note: data.note,
            ord: data.ord,
          })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchData();
              this.dialogEdit.open = false;
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
