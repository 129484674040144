import { defineStore } from "pinia";
import Vue from "vue";

export const useRecordStore = defineStore("records", {
  state: () => ({
    archiveid: 0,
    selected_records: [],
    data: [],
    loading: false,
    pagesize: 10,
    pageindex: 1,
    totalrecords: 0,
    q: "",
  }),
  getters: {
    get_total_pages: (state) => {
      if (state.totalrecords) {
        return Math.ceil(state.totalrecords / state.pagesize);
      }
      return 0;
    },
    active_record: (state) => {
      if (state.selected_records && state.selected_records.length) {
        return state.selected_records[0];
      }
      return null;
    },
  },
  actions: {
    loadData(clear, old) {
      this.loading = true;
      if (clear) {
        this.selected_records = [];
      }
      if (this.archiveid) {
        Vue.hc
          .req()
          .get(
            `records?aid=${this.archiveid}&pagesize=${
              this.pagesize
            }&pageindex=${this.pageindex}&q=${this.q || ""}`
          )
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.data = x.Data.Payload;
              this.totalrecords = x.Data.Total;
              if (old) {
                for (var i = 0; i < this.data.length; i++) {
                  if (this.data[i].id == old.id) {
                    this.selected_records = [];
                    this.selected_records = [this.data[i]];
                  }
                }
              }
              this.loading = false;
            },
            (x) => {
              this.loading = false;
            }
          );
      } else {
        this.$reset();
      }
    },
    createOrUpdate(vm) {
      this.loading = true;
      if (vm.id) {
        Vue.hc
          .req()
          .put(`records`, vm)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.loadData(true, vm);
            },
            (x) => {
              this.loading = false;
            }
          );
      } else {
        Vue.hc
          .req()
          .post(`records`, vm)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.loadData();
            },
            (x) => {
              this.loading = false;
            }
          );
      }
    },
    delete() {
      this.loading = true;
      Vue.hc
        .req()
        .delete(`records/${this.active_record.id}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loadData(true);
          },
          (x) => {
            this.loading = false;
          }
        );
    },
  },
});
