<template>
  <v-container fluid style="padding: 0; min-height: 100%">
    <v-toolbar flat class="darken-2 elevation-5">
      <v-breadcrumbs :items="breads" large></v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container fluid>
      <v-card v-resize="onResize">
        <v-card-title style="padding-top: 5px; padding-bottom: 5px"
          >项目详细信息</v-card-title
        >
        <v-card-text ref="cardtxt">
          <v-container fluid style="padding-top: 5px; padding-bottom: 5px">
            <v-row dense>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">项目名称:</span>
              </v-col>
              <v-col cols="12" sm="1">
                <span>{{ project.name }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">类型:</span>
              </v-col>
              <v-col cols="12" sm="1">
                <span>{{ project.type }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">开始时间:</span>
              </v-col>
              <v-col cols="12" sm="1">
                <span>{{ project.start | moment("YYYY-MM-DD") }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">周期:</span>
              </v-col>
              <v-col cols="12" sm="1">
                <span>{{ project.term }}个月</span>
              </v-col>

              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">负责部门:</span>
              </v-col>
              <v-col cols="12" sm="1">
                <span>{{ project.department }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">负责人:</span>
              </v-col>
              <v-col cols="12" sm="1">
                <span>{{ project.charger }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">主管领导:</span>
              </v-col>
              <v-col cols="12" sm="1">
                <span>{{ project.leader }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">项目预算:</span>
              </v-col>
              <v-col cols="12" sm="1">
                <span>{{ project.budget }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">经费来源:</span>
              </v-col>
              <v-col cols="12" sm="1">
                <span>{{ project.fundsource }}</span>
              </v-col>
              <v-col cols="12" sm="1" style="text-align: right">
                <span class="grey--text text--darken-1">备注:</span>
              </v-col>
              <v-col cols="12" sm="5">
                <span>{{ project.note }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-sheet outlined style="padding: 0; min-height: 100%">
          <v-tabs background-color="blue-grey lighten-5" v-model="tab">
            <v-tab @click="$refs.timeline && $refs.timeline.fetchData()">时间线</v-tab>
            <v-tab @click="$refs.map && $refs.map.fetchData()">地图</v-tab>
            <v-tab>项目文档</v-tab>
            <v-tab>子项目</v-tab>
            <v-tab v-if="inrole([1, 3])">项目甲方</v-tab>
            <v-tab v-if="inrole([1, 3])">项目乙方</v-tab>
            <v-tab-item>
              <timeline-tab type="project" :id="id" ref="timeline"></timeline-tab>
            </v-tab-item>
            <v-tab-item>
              <sub-project-map-tab
                ref="map"
                type="project"
                :id="id"
                :mapheight="mapheight"
              ></sub-project-map-tab>
            </v-tab-item>
            <v-tab-item>
              <document-tab :parentid="id" parenttype="project"></document-tab>
            </v-tab-item>
            <v-tab-item>
              <sub-project-tab :projectid="id"></sub-project-tab>
            </v-tab-item>
            <v-tab-item>
              <project-accounts-tab
                :projectid="id"
                role="项目甲方"
              ></project-accounts-tab>
            </v-tab-item>
            <v-tab-item>
              <project-accounts-tab
                :projectid="id"
                role="项目乙方"
              ></project-accounts-tab>
            </v-tab-item>
          </v-tabs>
        </v-sheet>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { useUserStore } from "@/stores/userStore.js";
export default {
  props: ["id"],
  data: () => ({
    mapheight: "100",
    tab: 0,
    loading: false,
    breads: [
      {
        text: "项目管理",
        disabled: false,
        to: "/",
      },
      {
        text: "",
        disabled: true,
      },
    ],
    project: {},
  }),
  watch: {
    id: {
      handler() {
        this.fetchProject();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchProject();
    this.onResize();
  },
  methods: {
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
    onResize() {
      var offset = 225 + this.$refs.cardtxt.clientHeight;
      var mh = window.innerHeight - offset - 20;
      mh = mh > 400 ? mh : 400;
      this.mapheight = mh;
    },
    fetchProject() {
      this.loading = true;
      this.$hc
        .req()
        .get(`projects/${this.id}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.project = x.Data;
            this.breads[1].text = this.project.name;
          },
          (x) => {}
        );
    },
  },
};
</script>
