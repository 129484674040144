<template>
  <v-dialog v-model="config.open" persistent max-width="1200px" max-height="800px">
    <v-card>
      <v-card-title>
        <span class="headline">请选择归档案卷</span>
      </v-card-title>
      <v-container fluid class="minhight100">
        <v-row class="minhight100">
          <v-col cols="3" style="padding-top: 0px">
            <c-archive-folders :config="folders" :vh="height"></c-archive-folders>
          </v-col>
          <v-col cols="9" style="padding-top: 0px">
            <v-row>
              <v-col
                cols="12"
                :style="{
                  height: height + 'px',
                  'padding-bottom': '0px',
                }"
              >
                <c-archives
                  :config="archives"
                  :folderid="folders.current.id"
                  :root="folders.root"
                  :rules="folders.current.rules"
                  :vh="height"
                ></c-archives>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="config.open = false">取消</v-btn>
        <v-btn
          color="warning"
          :disabled="!(archives.archive && archives.archive.id)"
          @click="archiveProject"
          >确定</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ArchiveProjectDialog",
  props: ["config", "success"],
  data: () => ({
    height: 700,
    folders: {
      current: {
        rules: [],
      },
    },
    archives: {
      archive: {},
    },
  }),
  methods: {
    archiveProject() {
      this.$confirm("确定要将项目文件归档到所选案卷吗? ").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(
              `projects?pid=${this.config.id}&archiveid=${this.archives.archive.id}`
            )
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.$emit("success");
                this.config.open = false;
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
