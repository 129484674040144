<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>项目管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          label="搜索项目"
          v-model="q"
          dense
          flat
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          v-if="profile.role != 3"
          @click="
            currentProject = {};
            projectDialog = true;
          "
          >新建项目</v-btn
        >
      </v-toolbar>
    </v-card>

    <v-card style="margin-top: 20px">
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
      >
        <template v-slot:item.start="props">{{
          props.item.start | moment("YYYY-MM-DD")
        }}</template>
        <template v-slot:item.op="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                small
                icon
                v-on="on"
                :to="`/projects/${props.item.projectid}/details`"
              >
                <v-icon small>remove_red_eye</v-icon>
              </v-btn>
            </template>
            <span>查看项目</span>
          </v-tooltip>
          <v-tooltip bottom v-if="inrole([1, 3])">
            <template v-slot:activator="{ on }">
              <v-btn
                color="orange"
                small
                icon
                v-on="on"
                @click="
                  currentProject = { ...props.item };
                  projectDialog = true;
                "
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>修改项目</span>
          </v-tooltip>

          <v-tooltip bottom v-if="profile.role == 1">
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                v-if="props.item.accountid != 1"
                @click.native.stop="archiveProject(props.item.projectid)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>归档项目</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有项目被找到</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
    <project-edit-dialog
      :data="currentProject"
      :open.sync="projectDialog"
      @success="fetchProjects"
    ></project-edit-dialog>
    <archive-project-dialog
      :config="archiveProjectDialog"
      @success="fetchProjects"
    ></archive-project-dialog>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  //components: { ProjectEditDialog },
  data() {
    return {
      loading: true,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      data: {},
      currentProject: {},
      projectDialog: false,
      archiveProjectDialog: {
        id: 0,
        open: false,
      },
      headers: [
        {
          text: "ID",
          value: "projectid",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "项目名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "项目类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "负责人",
          value: "charger",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "开始时间",
          value: "start",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "项目周期(月)",
          value: "term",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchProjects();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchProjects();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    ...mapState(useUserStore, ["profile"]),
  },
  methods: {
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetchProjects();
      }
    },
    fetchProjects() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `projects?pagesize=${this.pagination.rowsPerPage}&pageindex=${
            this.pagination.page
          }&q=${this.q || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    archiveProject(id) {
      this.archiveProjectDialog.id = id;
      this.archiveProjectDialog.open = true;
    },
  },
};
</script>
