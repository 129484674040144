<template>
  <v-dialog v-model="open" max-width="800px" max-height="600px">
    <v-card outlined style="height: 70%" tile>
      <v-toolbar color="blue lighten-4" dense>
        <v-toolbar-title class="text-subtitle-1"
          >查看文件: {{ data.name }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div
          v-viewer
          style="height: 100%; margin: 0px auto; text-align: center; cursor: pointer"
        >
          <template>
            <img
              :src="imageData"
              :alt="data.name"
              style="max-height: 100%; max-width: 100%"
            />
          </template>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="data.previous_id == 0"
          @click="loadImg(data.previous_id)"
          >上一个</v-btn
        >
        <v-btn
          color="primary"
          :disabled="data.next_id == 0"
          @click="loadImg(data.next_id)"
          >下一个</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useDocumentStore } from "@/stores/documentStore.js";
export default {
  name: "DocumentViewDialog",
  data() {
    return {
      open: false,
      imageData: "",
      aid: 0,
      rid: 0,
      data: {
        name: "",
      },
    };
  },

  methods: {
    openDialog(doc) {
      this.aid = doc.archiveid;
      this.rid = doc.recordid;
      this.loadImg(doc.id);
      this.open = true;
    },
    loadImg(id) {
      const store = useDocumentStore();
      store.preview(id, this.aid, this.rid).subscribe(
        (x) => {
          this.data = x.Data;
          this.imageData = `data:${x.Data.mime};base64,${x.Data.base64}`;
          this.$forceUpdate();
        },
        (x) => {}
      );
    },
  },
};
</script>
