<template>
  <v-container fluid style="padding-top: 150px">
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md3 style="padding-top: 100px; padding-bottom: 25px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>登录</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="loginForm"
              v-model="valid"
              lazy-validation
              dense
              autocomplete="random-string"
            >
              <v-text-field
                v-model="vm.account"
                :rules="rules.nameRules"
                prepend-icon="person"
                autocomplete="new-password"
                label="用户名"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                v-model="vm.password"
                autocomplete="new-password"
                :rules="rules.passRules"
                prepend-icon="lock"
                label="密码"
                type="password"
                @keyup.enter.stop="login"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" @click="login">登录</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { useUserStore } from "@/stores/userStore.js";
export default {
  data: () => ({
    valid: false,
    vm: {
      account: "",
      password: "",
    },
    rules: {
      nameRules: [(v) => !!v || "请填写用户名"],
      passRules: [(v) => !!v || "请填写密码"],
    },
  }),

  methods: {
    login() {
      if (!this.$refs.loginForm.validate()) return;
      const userStore = useUserStore();
      userStore.login(this, { username: this.vm.account, password: this.vm.password });
    },
  },
};
</script>
