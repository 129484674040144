<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card outlined tile>
        <v-toolbar color="blue lighten-4" dense>
          <v-toolbar-title class="text-subtitle-1">{{ title }} </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="实体名称"
            v-model="vm.name"
            :rules="validators.name"
          ></v-text-field>
          <v-text-field
            label="实体编号"
            v-model="vm.value"
            :rules="validators.value"
          ></v-text-field>
          <v-textarea label="备注" v-model="vm.note" :rows="2"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="open = false">取消</v-btn>
          <v-btn color="warning" @click="createOrEdit()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "CategoryEditDialog",
  data() {
    return {
      open: false,
      title: "",
      loading: false,
      valid: false,
      vm: {
        name: "",
        value: "",
        note: "",
        type: 0,
      },
      validators: {
        name: [(v) => !!v || "请填写实体名称"],
        value: [(v) => !!v || "请填写实体编号"],
      },
    };
  },

  methods: {
    openDialog(category) {
      if (category) {
        this.title = "编辑实体分类";
        this.vm = { ...category };
      } else {
        this.title = "新建实体分类";
        this.vm = {
          name: "",
          value: "",
          note: "",
          type: 0,
        };
      }
      this.open = true;
    },
    createOrEdit() {
      if (!this.$refs.form.validate()) return;
      this.$emit("success", this.vm);
      this.open = false;
    },
  },
};
</script>
