<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>用户管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          label="搜索用户"
          v-model="q"
          dense
          flat
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="profile.role != 3" @click="openAccountDialog(null)"
          >新建用户</v-btn
        >
      </v-toolbar>
    </v-card>

    <v-card style="margin-top: 20px">
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
      >
        <template v-slot:item.op="props">
          <v-tooltip
            bottom
            v-if="profile.role == 1 && profile.id != props.item.accountid"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="orange"
                small
                icon
                v-on="on"
                @click="openRestpasswordDialog(props.item)"
              >
                <v-icon small>key</v-icon>
              </v-btn>
            </template>
            <span>重设密码</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="orange"
                small
                icon
                v-on="on"
                @click="openAccountDialog(props.item)"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>修改用户信息</span>
          </v-tooltip>

          <v-tooltip bottom v-if="props.item.id != profile.id && profile.role == 1">
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                v-if="props.item.accountid != 1"
                @click.native.stop="deleteUser(props.item.accountid)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>删除用户</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有用户被找到</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>

      <v-dialog v-model="dialogSet.open" persistent max-width="600px">
        <v-form
          ref="addForm"
          v-model="dialogSet.info.valid"
          lazy-validation
          autocomplete="random-string"
        >
          <v-card>
            <v-card-title>
              <span class="headline">重设密码</span>
            </v-card-title>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    type="password"
                    v-model="dialogSet.info.data.password1"
                    :rules="rules.mimaRules"
                    :counter="50"
                    maxlength="50"
                    label="新密码"
                  ></v-text-field>
                  <v-text-field
                    type="password"
                    v-model="dialogSet.info.data.password2"
                    :rules="[confirmPass]"
                    :counter="50"
                    maxlength="50"
                    label="确认新密码"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialogSet.open = false">取消</v-btn>
              <v-btn
                color="warning"
                :disabled="!dialogSet.info.valid"
                @click="resetPassword(dialogSet.info.data)"
                >确定</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogAccount.open" persistent max-width="600px">
        <v-form
          ref="accountForm"
          v-model="dialogAccount.info.valid"
          lazy-validation
          autocomplete="random-string"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ dialogAccount.info.title }}</span>
            </v-card-title>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="dialogAccount.info.data.username"
                    :rules="rules.username"
                    :counter="50"
                    maxlength="50"
                    v-if="dialogAccount.info.new"
                    label="账号"
                  ></v-text-field>
                  <v-text-field
                    v-model="dialogAccount.info.data.name"
                    :rules="rules.name"
                    :counter="50"
                    maxlength="50"
                    label="姓名"
                  ></v-text-field>
                  <v-text-field
                    v-model="dialogAccount.info.data.contact"
                    :rules="rules.contact"
                    :counter="50"
                    label="联系方式"
                  ></v-text-field>
                  <v-select
                    v-model="dialogAccount.info.data.role"
                    :items="enums.RoleTypes"
                    item-text="name"
                    item-value="name"
                    label="权限级别"
                  ></v-select>
                  <v-text-field
                    v-if="this.dialogAccount.info.new"
                    type="password"
                    v-model="dialogAccount.info.data.password1"
                    :rules="rules.mimaRules"
                    :counter="50"
                    maxlength="50"
                    label="密码"
                  ></v-text-field>
                  <v-text-field
                    v-if="this.dialogAccount.info.new"
                    type="password"
                    v-model="dialogAccount.info.data.password2"
                    :rules="[confirmPassAccount]"
                    :counter="50"
                    maxlength="50"
                    label="确认密码"
                  ></v-text-field>
                  <v-text-field
                    v-model="dialogAccount.info.data.company"
                    :counter="50"
                    label="工作单位"
                  ></v-text-field>
                  <v-text-field
                    v-model="dialogAccount.info.data.department"
                    :counter="50"
                    label="部门"
                  ></v-text-field>
                  <v-text-field
                    v-model="dialogAccount.info.data.title"
                    :counter="50"
                    label="职务"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialogAccount.open = false">取消</v-btn>
              <v-btn
                color="warning"
                :disabled="!dialogAccount.info.valid"
                @click="createOrEditAccount(dialogAccount.info.data)"
                >确定</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  data() {
    return {
      loading: true,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      dialogSet: {
        open: false,
        info: {
          valid: false,
          data: {},
        },
      },
      dialogAccount: {
        open: false,
        info: {
          valid: false,
          data: {},
        },
      },
      data: {},
      rules: {
        mimaRules: [
          (v) => !!v || "请填写密码",
          (v) =>
            (v && v.length <= 50 && v.length >= 6) || "密码长度必须在6到50个字符之间",
        ],
        username: [
          (v) => !!v || "请填写账号",
          (v) =>
            (v && v.length <= 50 && v.length >= 2) || "账号长度必须在2到50个字符之间",
        ],
        name: [
          (v) => !!v || "请填写姓名",
          (v) =>
            (v && v.length <= 50 && v.length >= 2) || "姓名长度必须在2到50个字符之间",
        ],
        contact: [
          (v) => !!v || "请填写联系方式",
          (v) => (v || "").length <= 50 || "联系方式必须少于50个字符",
        ],
      },
      headers: [
        {
          text: "ID",
          value: "accountid",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "账号",
          value: "username",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "姓名",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "权限等级",
          value: "role",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "联系方式",
          value: "contact",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchUsers();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchUsers();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    ...mapState(useUserStore, ["enums"]),
    ...mapState(useUserStore, ["profile"]),
  },
  methods: {
    confirmPass(value) {
      if (!value) return "请填写确认新密码";
      return value === this.dialogSet.info.data.password1 || "密码不一致";
    },
    confirmPassAccount(value) {
      if (!value) return "请填写确认新密码";
      return value === this.dialogAccount.info.data.password1 || "密码不一致";
    },
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.findUsers();
      }
    },
    findUsers() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchUsers();
      }
    },
    fetchUsers() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `accounts?pagesize=${this.pagination.rowsPerPage}&pageindex=${
            this.pagination.page
          }&q=${this.q || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteUser(id) {
      this.$confirm("确定要删除这个用户吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`accounts?aid=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchUsers();
              },
              (x) => {}
            );
        }
      });
    },
    openRestpasswordDialog(data) {
      this.dialogSet.info.data.accountid = data.accountid;
      this.dialogSet.open = true;
    },
    openAccountDialog(data) {
      if (data) {
        this.dialogAccount.info.new = false;
        this.dialogAccount.info.title = "修改用户信息";
        this.dialogAccount.info.data = { ...data };
      } else {
        this.dialogAccount.info.new = true;
        this.dialogAccount.info.title = "新建用户";
        this.dialogAccount.info.data = {
          role: this.profile.role + "",
        };
      }

      this.dialogAccount.open = true;
    },
    resetPassword(data) {
      if (!this.$refs.addForm.validate()) return;
      this.$hc
        .req()
        .put(`accounts/pass`, {
          accountid: data.accountid,
          old: data.old,
          pass1: data.password1,
          pass2: data.password2,
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.dialogSet.open = false;
          },
          (x) => {}
        );
    },
    createOrEditAccount(data) {
      if (!this.$refs.accountForm.validate()) return;
      if (this.dialogAccount.info.new) {
        this.$hc
          .req()
          .post(`accounts`, {
            name: data.name,
            role: data.role,
            username: data.username,
            contact: data.contact,
            password: data.password1,
            company: data.company,
            department: data.department,
            title: data.title,
          })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchUsers();
              this.dialogAccount.open = false;
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .put(`accounts`, {
            accountid: data.accountid,
            role: data.role,
            name: data.name,
            contact: data.contact,
            company: data.company,
            department: data.department,
            title: data.title,
          })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchUsers();
              this.dialogAccount.open = false;
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
