import { defineStore } from "pinia";
import Vue from "vue";

export const useCategoryStore = defineStore("categories", {
  state: () => ({
    type: 0,
    active_categories: [],
    data: [],
  }),
  getters: {
    selected_category: (state) => {
      if (state.active_categories && state.active_categories.length) {
        return state.active_categories[0];
      }
      return 0;
    },
  },
  actions: {
    loadData() {
      Vue.hc
        .req()
        .get(`categoriestree?type=${this.type}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.$patch({ data: x.Data });
          },
          (x) => {}
        );
    },
    createOrUpdate(vm) {
      if (vm.id) {
        Vue.hc
          .req()
          .put(`categoriestree`, vm)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.loadData();
            },
            (x) => {}
          );
      } else {
        if (this.selected_category) {
          vm.parentid = this.selected_category;
        }
        Vue.hc
          .req()
          .post(`categoriestree`, vm)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.loadData();
            },
            (x) => {}
          );
      }
    },
    delete() {
      Vue.hc
        .req()
        .delete(`categoriestree/${this.selected_category}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.active_categories = [];
            this.loadData();
          },
          (x) => {}
        );
    },
    getSelectedNodeCopy() {
      if (this.selected_category) {
        var f = Vue.g.treeFindOne(this.selected_category, this.data);
        var fs = JSON.stringify(f);
        return JSON.parse(fs);
      }
      return null;
    },
  },
});
