<template>
  <div>
    <v-navigation-drawer
      v-model="sidebar"
      clipped
      app
      width="200"
    >
      <v-toolbar
        class="darken-3"
        v-if="profile && profile.name"
      >
        <v-toolbar-title> {{ profile.name }}</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>
      <v-list>
        <v-list-item
          @click.stop
          to="/"
          v-if="inrole([1, 2,5])"
        >
          <v-list-item-content>
            <v-list-item-title>档案管理</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click.stop
          to="/archiverequests"
          v-if="inrole([1, 2,5])"
        >
          <v-list-item-content>
            <v-list-item-title>入档申请</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-title>档案借阅</v-list-item-title>
          </template>
          <v-list-item
            @click.stop
            to="/myborrowrequests"
          >
            <v-list-item-content>
              <v-list-item-title>我的借阅申请</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click.stop
            to="/borrowrequestconfirmation"
          >
            <v-list-item-content>
              <v-list-item-title>我的审批</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click.stop
            to="/borrowrequestmanagement"
            v-if="inrole([1])"
          >
            <v-list-item-content>
              <v-list-item-title>借阅管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-title>项目管理</v-list-item-title>
          </template>
          <v-list-item
            @click.stop
            to="/projects"
          >
            <v-list-item-content>
              <v-list-item-title>我的项目</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click.stop
            to="/archivedprojects"
          >
            <v-list-item-content>
              <v-list-item-title>归档项目</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          @click.stop
          to="/profile"
        >
          <v-list-item-content>
            <v-list-item-title>用户信息</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          no-action
          v-if="inrole([1])"
        >
          <template v-slot:activator>
            <v-list-item-title>系统管理</v-list-item-title>
          </template>
          <v-list-item
            @click.stop
            to="/system/accounts"
          >
            <v-list-item-content>
              <v-list-item-title>用户管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click.stop
            to="/system/dictionary"
          >
            <v-list-item-content>
              <v-list-item-title>系统字典</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click.stop
            to="/system/categories"
          >
            <v-list-item-content>
              <v-list-item-title>实体分类</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click.stop
            to="/system/verify"
          >
            <v-list-item-content>
              <v-list-item-title>案卷审核</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "App",
  data: () => ({
    drawer: true,
  }),
  computed: {
    ...mapState(useUserStore, ["profile"]),
    ...mapWritableState(useUserStore, ["sidebar"]),
  },
  methods: {
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
  },
};
</script>
