<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="random-string"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-file-input
                label="选择上传文件"
                @change="getFile($event)"
                v-model="data.file"
                :rules="fileRule"
              ></v-file-input>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="data.name"
                :rules="rules.name"
                :counter="50"
                maxlength="50"
                label="文档名称"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-combobox
                v-model="data.type"
                :rules="rules.type"
                :items="documentTypes"
                label="文档类型"
              ></v-combobox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="data.charger"
                :rules="rules.charger"
                :counter="50"
                label="负责人"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-textarea
                name="hack-chrome"
                label="备注"
                :rows="3"
                v-model="data.note"
                autocomplete="false"
                :counter="500"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)"
            >取消</v-btn
          >
          <v-btn color="warning" :disabled="!valid" @click="createOrEdit()"
            >确定</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "DocumentEditDialog",
  props: ["data", "open", "parentid", "parenttype"],
  data: () => ({
    valid: false,
    startDialog: false,
    documentTypes: [
      "项目需求",
      "招标文件",
      "中标文件",
      "中标通知书",
      "项目合同",
    ],
    rules: {
      file: [(v) => !!v || "请选择上传文件"],
      name: [
        (v) => !!v || "请填写文档名称",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) ||
          "文档名称长度必须在2到50个字符之间",
      ],
      charger: [
        (v) => !!v || "请填写负责人",
        (v) =>
          (v && v.length <= 50 && v.length >= 2) ||
          "负责人长度必须在2到50个字符之间",
      ],
      type: [(v) => !!v || "请选择或填写文档类型"],
    },
  }),
  computed: {
    title() {
      if (this.data && this.data.id) {
        return "修改文档";
      }
      return "上传文档";
    },
    fileRule() {
      if (this.data && this.data.id) {
        return [];
      }
      return [(v) => !!v || "请选择上传文件"];
    },
  },
  methods: {
    createOrEdit() {
      if (!this.$refs.form.validate()) return;
      var fe = new FormData();
      if (this.data && this.data.id) {
        fe.append("name", this.data.name);
        fe.append("type", this.data.type);
        fe.append("charger", this.data.charger);
        fe.append("note", this.data.note);
        fe.append("id", this.data.id);
        if (this.data.file) {
          fe.append("file", this.data.file);
        }
        this.$hc
          .req()
          .put(`documents`, fe)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      } else {
        fe.append("name", this.data.name);
        fe.append("type", this.data.type);
        fe.append("charger", this.data.charger);
        fe.append("note", this.data.note || "");
        fe.append("parent", this.parenttype);
        fe.append("pid", this.parentid);
        if (this.data.file) {
          fe.append("file", this.data.file);
        }
        this.$hc
          .req()
          .post(`documents`, fe)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      }
    },
    getFile(evt) {
      var file = event.target.files;
      if (file && file.length > 0) {
        this.data.name = file[0].name;
      }
    },
  },
};
</script>
