<template>
  <v-sheet>
    <v-card>
      <v-card-title>
        <v-text-field
          label="搜索文档"
          v-model="q"
          dense
          flat
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          v-if="inrole([1, 3, 4])"
          color="primary"
          @click="
            current = {};
            dialog = true;
          "
          >上传文档</v-btn
        >
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
      >
        <template v-slot:item.createdtime="props">{{
          props.item.createdtime | moment("YYYY-MM-DD")
        }}</template>

        <template v-slot:item.download="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="green" small icon v-on="on" @click="download(props.item.id)">
                <v-icon small>download</v-icon>
              </v-btn>
            </template>
            <span>下载文档</span>
          </v-tooltip>
        </template>
        <template v-slot:item.op="props">
          <v-tooltip
            bottom
            v-if="
              inrole([1, 3]) || (profile.role == 4 && props.item.createdby == profile.id)
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="orange"
                small
                icon
                v-on="on"
                @click="
                  current = { ...props.item };
                  dialog = true;
                "
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>修改文档</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              inrole([1, 3]) || (profile.role == 4 && props.item.createdby == profile.id)
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                v-if="props.item.accountid != 1"
                @click.native.stop="deleteDocument(props.item.id)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>删除文档</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有文档</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
    <document-edit-dialog
      :data="current"
      :open.sync="dialog"
      :parentid="parentid"
      :parenttype="parenttype"
      @success="fetchData"
    ></document-edit-dialog>
  </v-sheet>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import fileDownload from "js-file-download";
import Axios from "axios";
export default {
  name: "DocumentTab",
  props: ["parentid", "parenttype"],
  data() {
    return {
      loading: true,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      data: {},
      current: {},
      dialog: false,
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "文档名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "文档类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "负责人",
          value: "charger",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "创建时间",
          value: "createdtime",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "备注",
          value: "note",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "下载",
          value: "download",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },

    ...mapState(useUserStore, ["profile"]),
    attachmenturl() {
      return process.env.VUE_APP_DOC_ROOT;
    },
    apiroot() {
      return process.env.VUE_APP_API;
    },
  },
  methods: {
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetchData();
      }
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `documents?id=${this.parentid}&type=${this.parenttype}&pagesize=${
            this.pagination.rowsPerPage
          }&pageindex=${this.pagination.page}&q=${this.q || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteDocument(id) {
      this.$confirm("确定要删除这个文档吗?").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`documents?did=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
    download(id) {
      this.$ns.cast("lock", true);
      const tk = this.$ls.get("token", null, String);
      let u = JSON.parse(tk);
      Axios({
        method: "get",
        url: `${this.apiroot}documents/download?id=${id}`,
        headers: {
          Authorization: `Bearer ${u.Token}`,
        },
        responseType: "blob",
      })
        .then((x) => {
          var filename = x.request.getResponseHeader("pd-name");
          if (!filename) {
            this.$ns.cast("snack", {
              text: "没有权限下载文档",
              color: "error",
            });
          } else {
            fileDownload(x.data, filename);
          }
        })
        .finally(() => this.$ns.cast("lock", false));
    },
  },
};
</script>
