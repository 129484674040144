import { defineStore } from "pinia";
import Vue from "vue";
import moment from "moment";

export const useUserStore = defineStore("user", {
  state: () => ({
    token: null,
    profile: null,
    enums: null,
    sidebar: true,
  }),
  getters: {
    userToken: (state) => {
      try {
        var tk = null;
        if (state.token) {
          tk = state.token;
        } else {
          var ts = Vue.ls.get("token", null, String);
          if (ts) {
            tk = JSON.parse(ts);
            state.token = tk;
          }
        }
        if (tk) {
          var now = moment();
          var issue = moment(tk.IssueDate || "1900-01-01");
          var d = now.diff(issue, "minutes");
          if (d > (tk.ExpiredIn || 720)) {
            tk = null;
            Vue.ls.remove("token");
            state.token = null;
            return null;
          }
          return tk;
        }
      } catch (err) {}
      return null;
    },
  },
  actions: {
    isUserInRoles(arr) {
      if (!this.profile) return false;
      for (let i in arr) {
        if (this.profile.role == arr[i]) {
          return true;
        }
      }
      return false;
    },
    login(app, vm) {
      Vue.hc
        .req()
        .post("accounts/login", vm, (x) => {
          if (x.Success) {
            Vue.ls.set("token", JSON.stringify(x.Data));
            this.$patch({
              token: x.Data,
            });
          }
        })
        .get("accounts/profile", (x) => {
          this.$patch({ profile: x.Data });
        })
        .get(`settings/enums`, (x) => {
          this.$patch({ enums: x.Data });
        })
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "登陆成功",
        })
        .subscribe(
          (x) => {
            if (this.isUserInRoles([1, 2])) {
              app.$router.replace("/");
            } else {
              app.$router.replace("/projects");
            }
          },
          (x) => {
            app.$ns.cast("snack", {
              text: "用户名或密码错误",
              color: "error",
            });
          }
        );
    },

    logout() {
      Vue.ls.remove("token");
      this.profile = null;
      this.token = null;
    },
    getUserProfile() {
      return Vue.hc
        .req()
        .get("accounts/profile", (x) => this.$patch({ profile: x.Data }))
        .get(`settings/enums`, (x) => {
          this.$patch({ enums: x.Data });
        })
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        });
    },
  },
});
