import Vue from "vue";
import App from "../App.vue";
import Login from "../views/Login.vue";
import Router from "vue-router";
import Projects from "../views/Projects.vue";
import ArchivedProjects from "../views/ArchivedProjects.vue";
import Accounts from "../views/Systems/Accounts.vue";
import Profile from "../views/Profile.vue";
import ProjectDetails from "../views/ProjectDetails.vue";
import SubProjectDetails from "../views/SubProjectDetails.vue";
import Dashboard from "../views/Dashboard.vue";
import ProgressDetails from "../views/ProgressDetails.vue";
import SystemDictionary from "../views/Systems//SystemDictionary.vue";
import SystemLanding from "../views/Systems/SystemLanding.vue";
import SystemCategories from "../views/Systems/Categories.vue";
import DocumentsManagement from "../views/DocumentsManagement.vue";
import ArchiveVerify from "../views/Systems/ArchiveVerify.vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import MyBorrowRequests from "../views/borrowrequests/MyBorrowRequests.vue"
import BorrowRequestConfirmation from "../views/borrowrequests/BorrowRequestConfirmation.vue"
import AdminBorrowRequests from "../views/borrowrequests/AdminBorrowRequests.vue"
import ArchiveRequests from "../views/ArchiveRequests.vue"

Vue.use(Router);
Vue.use(PiniaVuePlugin);
const p = createPinia();
var r = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
      default: true,
    },
    {
      path: "/",
      component: App,
      children: [
        {
          path: "dashboard",
          name: "dashboard",
          component: Dashboard,
          default: true,
        },
        {
          path: "/",
          name: "archives_folders",
          component: DocumentsManagement,
          default: true,
        },
        {
          path: "projects/:id/details",
          name: "projectdetails",
          component: ProjectDetails,
          props: true,
        },
        {
          path: "profile",
          name: "profile",
          component: Profile,
        },
        {
          path: "projects",
          name: "projects",
          component: Projects,
        },
        {
          path: "archivedprojects",
          name: "archivedprojects",
          component: ArchivedProjects,
        },
        {
          path: "subprojects/:id/details",
          name: "subprojectdetails",
          component: SubProjectDetails,
          props: true,
        },
        {
          path: "progress/:id/details",
          name: "progressdetails",
          component: ProgressDetails,
          props: true,
        },
        {
          path: "myborrowrequests",
          name: "myborrowrequests",
          component: MyBorrowRequests,
        },
        {
          path: "borrowrequestconfirmation",
          name: "borrowrequestconfirmation",
          component: BorrowRequestConfirmation,
        },
        {
          path: "borrowrequestmanagement",
          name: "borrowrequestmanagement",
          component: AdminBorrowRequests,
        },
        {
          path: "archiverequests",
          name: "archiverequests",
          component: ArchiveRequests,
        },
        {
          path: "system",
          name: "systemlanding",
          component: SystemLanding,
          children: [
            {
              path: "accounts",
              name: "system_accounts",
              component: Accounts,
            },
            {
              path: "dictionary",
              name: "system_dictionary",
              component: SystemDictionary,
            },
            {
              path: "categories",
              name: "system_categories",
              component: SystemCategories,
            },
            {
              path: "verify",
              name: "system_verify",
              component: ArchiveVerify,
            },
          ],
        },
      ],
    },
  ],
});

r.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    next();
  } else {
    const userStore = useUserStore(p);
    const tk = userStore.userToken;
    if (tk) {
      try {
        let u = userStore.profile;
        if (!u) {
          userStore.getUserProfile().subscribe((x) => {
            u = userStore.profile;
            if (to.path.startsWith("/system")) {
              if (userStore.isUserInRoles([1])) {
                next();
              } else {
                next("/login");
              }
            } else if (to.path == "/") {
              if (userStore.isUserInRoles([1, 2,5])) {
                next();
              } else {
                next("/login");
              }
            } else {
              next();
            }
          });
        } else {
          if (to.path.startsWith("/system")) {
            if (userStore.isUserInRoles([1])) {
              next();
            } else {
              next("/login");
            }
          } else if (to.path == "/") {
            if (userStore.isUserInRoles([1, 2,5])) {
              next();
            } else {
              next("/login");
            }
          } else {
            next();
          }
        }
      } catch (ex) {
        next("/login");
      }
    } else {
      next("/login");
    }
  }
});
export const router = r;
export const pinia = p;
export function getUserStore() {
  return useUserStore(pinia);
}
