<template>
  <div style="min-height: 100%">
    <v-card>
      <v-toolbar>
        <v-toolbar-title>档案审核</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <v-card
      style="margin-top: 5px; overflow: hidden"
      v-resize="onResize"
      :height="containerHeight"
      flat
    >
      <v-container fluid class="minhight100">
        <v-row>
          <v-col
            cols="12"
            :style="{
              height: containerHeight / 2 + 'px',
              'padding-top': '0px',
              'padding-right': '5px',
              'padding-left': '5px',
            }"
          >
            <c-archives
              :canedit="false"
              :cancreate="false"
              :candelete="false"
              :canupload="false"
              :canimport="false"
              :verify-filter="true"
              :verify="true"
              toolbar="true"
              :vh="containerHeight / 2"
            ></c-archives>
          </v-col>
        </v-row>
        <v-row style="padding-top: 0px; margin-top: 0px">
          <v-col cols="4">
            <v-row>
              <v-col
                :style="{
                  height: containerHeight / 2 - 10 + 'px',
                  'padding-top': '0px',
                  'padding-bottom': '0px',
                  'padding-right': '5px',
                  'padding-left': '5px',
                }"
              >
                <c-documents
                  :canedit="false"
                  :candelete="false"
                  :vh="containerHeight / 2"
                ></c-documents>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8">
            <v-row>
              <v-col
                :style="{
                  height: containerHeight / 2 + 'px',
                  'padding-top': '0px',
                  'padding-bottom': '0px',
                  'padding-right': '5px',
                  'padding-left': '5px',
                }"
              >
                <c-records
                  :canedit="false"
                  :cancreate="false"
                  :candelete="false"
                  :canupload="false"
                  :canimport="false"
                  :vh="containerHeight / 2"
                ></c-records>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import { useArchiveStore } from "@/stores/archiveStore.js";
import { useRecordStore } from "@/stores/recordStore.js";
import { useDocumentStore } from "@/stores/documentStore.js";
export default {
  data() {
    return {
      open: false,
      loading: false,
      containerHeight: 100,
    };
  },
  computed: {
    ...mapState(useArchiveStore, ["active_archive", "verify_type"]),
    ...mapState(useRecordStore, ["active_record"]),
  },
  watch: {
    active_archive: {
      handler(a, b) {
        if (a && b && a.id == b.id) return;
        const store = useRecordStore();
        const documentStore = useDocumentStore();
        if (this.active_archive && this.active_archive.id) {
          store.$reset();
          store.archiveid = this.active_archive.id;
          documentStore.recordid = 0;
          store.loadData();
          documentStore.$reset();
          documentStore.archiveid = this.active_archive.id;
          documentStore.loadData();
        } else {
          store.$reset();
          documentStore.$reset();
        }
      },
    },
    active_record: {
      handler(a, b) {
        if (a && b && a.id == b.id) return;
        const documentStore = useDocumentStore();
        if (this.active_record && this.active_record.id) {
          documentStore.$reset();
          documentStore.archiveid = this.active_archive.id;
          documentStore.recordid = this.active_record.id;
          documentStore.loadData();
        } else {
          documentStore.$reset();
          documentStore.archiveid = this.active_archive.id;
        }
      },
    },
    verify_type: {
      handler(a, b) {
        const store = useArchiveStore();
        store.loadData(false);
      },
    },
  },
  mounted() {
    this.onResize();
    const archiveStore = useArchiveStore();
    const recordStore = useRecordStore();
    const documentStore = useDocumentStore();
    archiveStore.$reset();
    recordStore.$reset();
    documentStore.$reset();
    archiveStore.verify_type = -1;
    archiveStore.loadData();
  },
  methods: {
    onResize() {
      this.containerHeight = window.innerHeight - 148;
    },
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
  },
};
</script>
